import { useMsal } from "@azure/msal-react";
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';

export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    return (
        <div>
            <IconButton
                onClick={handleLogout}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
        </div>
    )
};
