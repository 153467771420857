// theme.js
// ... (sem alterações)

// useStyle.js
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      marginRight: theme.spacing(1),
    },
}));

export default useStyles;