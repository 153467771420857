import { useMsal } from "@azure/msal-react";
import Button from "@material-ui/core/Button";
import { loginRequest } from "../authConfig.js";

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    }

    return (
        <div>
            <Button
                onClick={handleLogin}
                color="inherit"
            >
                Login
            </Button>
        </div>
    )
};